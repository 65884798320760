/****************************************

FOOTER

****************************************/

.footer-form {
	@include paddingTopBottom;

	p.eyebrow {
		margin-bottom: #{$paddingMain/2};
	}

	.col {
		&:nth-of-type(1) {
			padding-right: #{$paddingMain};

			@media (max-width: bp(nav)) {
				padding-right: 0;
			}
		}
	}

	.form-cont {
		width: 100%;
		display: flex;
		margin: 0;

		.nf-form-cont {
			width: 100%;
		}

		nf-fields-wrap {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
		}

		nf-field {
			width: calc((100% - 30px) / 3);

			@media (max-width: bp(nav)) {
				width: 100%;
				margin-bottom: 10px;
			}

			&:nth-of-type(4) {
				width: 100%;
			}

			&:nth-of-type(5) {
				width: 100%;

				.field-wrap {
					display: flex;
					justify-content: flex-end;
				}

				.nf-field-element {
					width: auto;

					input {
						color: color(accent);
					}

					&:after {
						content: "";
						width: 30px;
						height: 30px;
						background-image: url("../images/green-arrow.svg");
						background-size: 100% auto;
						transform: rotate(180deg);
						display: block;
						position: absolute;
						top: 0;
						right: 0;
						pointer-events: none;
						transition: width $animationDuration, height $animationDuration;
					}
				}
			}

			&:last-child {
				display: none;
			}
		}

		nf-field textarea {
			height: 90px !important;
			font-family: $bodyFont;
		}

		.nf-form-content input[type="button"] {
			background: none !important;
			padding: 0px 40px 0 0 !important;
			height: 30px;

			&:hover {
			}
		}

		/*
		.nf-pass.field-wrap .nf-field-element:after {
			height: 35px;
			line-height: 35px;
		}

		.nf-error.field-wrap .nf-field-element:after {
			height: 33px;
			width: 35px;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
		}*/
	}
}

footer {
	background-color: color(grey);
	width: 100%;
	padding: #{$paddingMain/2};

	.contact {
		text-align: center;
	}

	.logo {
		width: 200px;
		flex: none;
		display: block;

		img {
			width: 100%;
			height: auto;
		}
	}

	.flex-center {
		justify-content: flex-start;

		@media (max-width: bp(nav)) {
			flex-direction: column;
		}

		.col {
			&:nth-of-type(1) {
				width: 200px;
				flex: none;
			}

			&:nth-of-type(2) {
				width: calc(100% - 200px - 200px);
				justify-content: center;
				flex: none;
				text-align: center;
				padding-left: 30px;

				@media (max-width: bp(nav)) {
					width: 100%;
					margin-top: #{$paddingMain/2};
					padding-left: 0;
				}

				p {
					font-size: 14px;
					margin: 0;
					color: color(white);
					text-align: left;

					@media (max-width: bp(nav)) {
						text-align: center;
					}
				}

				.fair-housing {
					display: flex;
					align-items: flex-start;

					img {
						width: 50px;
						height: auto;
						flex: none;
						margin-right: 10px;
					}

					p {
						font-size: 10px;
						line-height: 1.3;

						@media (max-width: bp(nav)) {
							text-align: left;
						}
					}
				}

				a {
					color: color(white) !important;
					padding-left: 20px;
					margin-left: 20px;
					border-left: 1px solid color(white);
				}
			}
		}
	}
}
