.cta {
	@include paddingTopBottom();

	.flex-center {
		* {
			width: 100%;

			@media (max-width: bp(nav)) {
				width: auto;
			}

			&:not(:last-child) {
				margin-right: $paddingMain;

				@media (max-width: bp(nav)) {
					margin-right: 0;
				}
			}
		}
	}
}
