.image_alternate_section {
	@include paddingTopBottom();

	.general_section {
		padding-bottom: 0;

		.flex-center {
			@media (max-width: bp(nav)) {
				flex-direction: column !important;
			}

			.col {
				&:nth-of-type(2) {
					padding-left: $paddingMain;

					@media (max-width: bp(nav)) {
						padding-left: 0;
					}
				}
			}
		}

		&:nth-of-type(even) {
			.flex-center {
				flex-direction: row-reverse;

				.col:nth-of-type(2) {
					padding-left: 0;
					padding-right: $paddingMain;

					@media (max-width: bp(nav)) {
						padding-right: 0;
					}
				}
			}
		}
	}
}
