.home-popup {
	position: fixed;
	z-index: 999;
	background: color(white);
	bottom: -200px;
	left: $largeSidebarWidth;
	width: $largeContainerWidth;
	height: 0px;

	border-radius: $borderRadius $borderRadius 0 0;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	transition: height 1s, bottom 1s;

	.scroll-cont {
		width: 100%;
		height: 100%;
		overflow: scroll;
		padding: $paddingMain #{$paddingMain/2} $paddingMain #{$paddingMain/2};
		position: relative;
		z-index: 0;
	}

	@media (max-width: 1500px) {
		left: #{$paddingMain/2};
		width: calc(100vw - #{$paddingMain/2 * 2});
	}

	@media (max-width: bp(nav)) {
		left: 0;
		width: 100%;
		padding: $paddingMain 20px;
	}

	&.active {
		height: calc(100vh - 125px);
		bottom: 0;

		@media (max-width: bp(nav)) {
			height: calc(100vh - 75px);
		}
	}

	.close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 35px;
		height: 35px;
		z-index: 9;
	}

	@media (max-width: bp(nav)) {
		.cont {
			width: 100%;
		}
	}

	.hero {
		.flex-center {
			align-items: stretch;
			margin-bottom: $paddingMain;

			@media (max-width: bp(nav)) {
				flex-direction: column-reverse;
			}

			.col {
				&:nth-of-type(1) {
					width: 55%;
					position: relative;
					border-radius: $borderRadius 0 0 $borderRadius;
					overflow: hidden;

					@media (max-width: bp(nav)) {
						width: 100%;
						border-radius: 0 0 $borderRadius $borderRadius;
					}

					.swiper-section,
					.swiper-overflow,
					.swiper-cont {
						height: 100%;

						@media (max-width: bp(nav)) {
							height: auto;
						}
					}

					.img-crop {
						height: 100%;
					}
				}

				&:nth-of-type(2) {
					width: 45%;
					padding: $paddingMain #{$paddingMain * 0.66};
					background: $peachGrad;
					margin-left: $gap;
					border-radius: 0 $borderRadius $borderRadius 0;
					overflow: hidden;
					display: flex;
					position: relative;
					flex-direction: column;

					@media (max-width: bp(nav)) {
						width: 100%;
						border-radius: $borderRadius $borderRadius 0 0;
						margin-left: 0;
						margin-bottom: 10px;
						padding: #{$paddingMain/2};
					}

					.tagline {
						position: absolute;
						top: 15px;
						right: 25px;
						text-align: right;
						font-weight: 500;
					}

					ul {
						display: flex;

						@media (max-width: bp(nav)) {
							flex-direction: column;
							margin-bottom: 1em;
						}

						li {
							padding: 10px;
							font-weight: 500;

							@media (max-width: bp(nav)) {
								padding: 0;
								margin: 0;
							}

							&.sml {
								@media (min-width: bp(nav)) {
									font-size: 14px;
									line-height: 1.4;
									text-align: center;
								}
							}

							&:nth-of-type(1) {
								padding-left: 0;
							}

							&:not(:last-of-type) {
								border-right: 1px solid color(white);

								@media (max-width: bp(nav)) {
									border: none;
								}
							}
						}
					}
				}
			}
		}
	}

	.btns {
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: bp(nav)) {
			flex-direction: column;
		}

		a {
			@media (max-width: bp(nav)) {
				width: 100%;
			}

			&:not(:last-of-type) {
				margin-right: 15px;

				@media (max-width: bp(nav)) {
					margin-right: 0;
					margin-bottom: 15px;
				}
			}
		}
	}

	.intro {
		p {
			text-align: center;
		}
	}

	.the-map {
		width: 100%;
		height: 250px;
		border-radius: $borderRadius;
	}

	.features {
		.card-layout {
			padding: #{$paddingMain/2} calc((100% - 1125px) / 2);
			margin: 0 auto;
			background: color(grey, underlines);
			max-height: 125px;
			overflow: hidden;
			transition: max-height 0.5s;

			@media (max-width: bp(nav)) {
				padding: #{$paddingMain/2};
			}

			.card {
				p {
					margin: 0;
				}
			}
		}

		.see-all {
			padding: #{$paddingMain/3} calc((100% - 1125px) / 2);
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			background: color(grey, underlines);
			color: color(accent);
			font-size: 1.8rem;
			cursor: pointer;

			&:after {
				content: "";
				width: 8px;
				height: 8px;
				border-right: 3px solid color(accent);
				border-top: 3px solid color(accent);
				transform: translate(0, -2px) rotate(135deg);
				margin-left: 15px;
				display: block;
				transition: transform 0.5s;
			}
		}

		&.active {
			.card-layout {
				max-height: 2000px;
			}

			.see-all:after {
				transform: translate(0, 5px) rotate(-45deg);
			}
		}
	}

	.highlights {
		margin: $paddingMain 0;

		.flex-center {
			@media (max-width: bp(nav)) {
				flex-direction: column;
			}

			.col {
				&:nth-of-type(1) {
					width: 33%;

					@media (max-width: bp(nav)) {
						width: 100%;
					}
				}

				&:nth-of-type(2) {
					width: 66%;
					padding: $paddingMain;

					@media (max-width: bp(nav)) {
						width: 100%;
						padding: #{$paddingMain/2} 0;
					}

					.card {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin-bottom: 20px;

						p {
							margin: 0;
							font-weight: 500;
						}

						.disc p {
							font-size: 1.2em;

							a {
								text-decoration: underline;
							}
						}

						span {
							width: 50px;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						img {
							height: 35px;
							width: auto;
							margin-right: 15px;
						}
					}
				}
			}
		}
	}

	.floorplans {
		margin: $paddingMain 0;

		.eyebrow {
			margin-bottom: 30px;
		}

		img {
			width: 100%;
			height: auto;
		}

		a {
			display: block;

			&:not(:last-of-type) {
				margin-bottom: 20px;
			}
		}
	}
}
