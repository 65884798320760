/****************************************

VARIABLES

****************************************/
@import "_functions";

// MSC
$closeDuration: 0.4s;
$fadeDuration: 0.6s;
$mobile: 768px;
$animationDuration: 0.5s;
$progressBarWidth: 36px;

//layout
$containerWidth: 1200px;
$sidebar: 360px;
$sidebarTablet: 265px;

$closeDuration: 0.4s;
$fadeDuration: 0.6s;
$mobile: 768px;
$sm: 576px; /* max sm is phones */
$md: 768px; /* max md is landscape phones */
$lg: 992px; /* max lg is tablets */
$xl: 1200px; /* max xl is desktop */

$borderRadius: 20px;

//fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,400&family=Playfair+Display:wght@400;700&display=swap");

$headlineFont: "Playfair Display", serif;
$bodyFont: "Montserrat", sans-serif;

//font sizes
$fontSizes: (
	h1: 6rem,
	h2: 5rem,
	h3: 4rem,
	h4: 3rem,
	h5: 2rem,
	p: 1.8rem,
	eyebrow: 1.4rem,
	disclaimer: 1.4rem,
	nav: 1.5rem,
	xlform: 5.2rem,
);

//break points
$breakPoints: (
	xs: 576px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px,
	nav: 992px,
	tablet: 1024px,
	sm-desktop: 1200px,
	lg-desktop: 1400px,
);

//Spacing
$paddingMain: 6rem;
$paddingMainMobile: 2rem;
$gap: 10px;

$elementSpacingDesktop: 2rem;
@mixin elementSpacing {
	margin-bottom: $elementSpacingDesktop;
}

@mixin paddingAllSides {
	padding: $paddingMain;

	@media (max-width: bp(nav)) {
		padding: $paddingMainMobile;
	}
}

@mixin paddingTopBottom {
	padding: $paddingMain 0;

	@media (max-width: bp(nav)) {
		padding: $paddingMainMobile 0;
	}
}

//layout
$largeContainerWidth: 1400px;
$largeSidebarWidth: calc((100vw - #{$largeContainerWidth}) / 2);
$containerWidth: 1125px;
$contSidebarWidth: calc((100vw - #{$containerWidth}) / 2);
$smallContainerWidth: calc(1125px - #{$paddingMain * 2});
$smallSidebarWidth: calc((100vw - #{$smallContainerWidth}) / 2);

//colors
$colors: (
	white: (
		base: #fff,
		transparent: rgba(255, 255, 255, 0.75),
	),
	black: (
		base: #2a2a2a,
	),
	grey: (
		base: #414141,
		underlines: #edf0f5,
	),
	accent: (
		base: #1aa590,
		hazyGreen: #97a5a0,
		paleGreen: #c1c9c6,
		orange: #f05136,
		paleOrange: #e9e0de,
		beige: #f2f1ed,
		paleBeige: #f1f0ec,
		paleRose: #e9e0db,
	),
);

$peachGrad: linear-gradient(90deg, #fccdba 0%, #f8ca9e 100%);
$lightBlueGrad: linear-gradient(90deg, #eef1f5 0%, #e6e9f0 100%);
