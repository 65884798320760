.video-bg {
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  video {
    position: relative;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 1;
    overflow: hidden;
  }
}
