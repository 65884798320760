.qd_intro_section.module {
	.map-col {
		height: 300px;
		width: 100%;

		.the-map {
			height: 100%;
		}
	}

	ul {
		@include bullets();
	}
}
