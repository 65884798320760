.featured_property {
	@include paddingTopBottom;

	.flex-center {
		align-items: stretch;

		@media (max-width: bp(nav)) {
			flex-direction: column;
		}

		.col {
			&:nth-of-type(1) {
				width: 55%;
				border-radius: 0 0 0 $borderRadius;
				overflow: hidden;
				min-height: 450px;
				padding: 0;

				@media (max-width: bp(nav)) {
					width: 100%;
					border-radius: 0;
					min-height: 300px;
				}
			}

			&:nth-of-type(2) {
				width: 45%;
				border-left: $gap solid color(white);
				background-image: $peachGrad;
				border-radius: 0 $borderRadius $borderRadius 0;
				padding: $paddingMain;
				display: flex;
				flex-direction: column;
				justify-content: center;

				@media (max-width: bp(nav)) {
					width: 100%;
					border-radius: 0 0 $borderRadius $borderRadius;
					border-left: 0;
					padding: #{$paddingMain/2};
				}

				ul {
					display: flex;

					li {
						padding: 10px 15px;
						font-weight: 500;

						&:nth-of-type(1) {
							padding-left: 0;
						}

						&:not(:last-of-type) {
							border-right: 1px solid color(white);
						}
					}
				}

				.btn {
					margin-top: #{$paddingMain/2};
				}
			}
		}
	}
}
