.internal_hero_section {
	margin-top: 100px;
	margin-bottom: #{$paddingMain / 2};

	h1,
	h2,
	h3,
	h4,
	h5,
	p {
		text-align: center;
	}
}
