.resources_section {
	text-align: center;

	.resources {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: $paddingMain;
	}

	h3 {
		margin-top: 20px;
		margin-bottom: 10px;
		font-family: $bodyFont;
		text-decoration: underline;
		text-transform: uppercase;
		font-weight: bold;
	}

	a {
		font-size: 18px;
		color: color(accent);
		text-decoration: none;
		margin-bottom: 10px;
	}
}
