.blog-entry {
	display: flex;
	align-items: flex-start;

	&:not(:last-of-type) {
		margin-bottom: $paddingMain;
	}

	.thumbnail {
		width: 30%;

		img {
			width: 100%;
			height: auto;
		}
	}

	.inner {
		width: 70%;
		padding-left: $paddingMain;

		a {
			text-decoration: none;
		}
	}
}

.blog-posts {
	padding: $paddingMain 0;
}

.blog {
	.navigation {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		margin-top: $paddingMain;

		a,
		.current {
			color: color(white);
			background: color(accent);
			padding: 5px 10px;
			text-decoration: none;
			font-size: 1.6rem;
		}

		.current {
			background-color: color(accent, orange);
		}
	}
}
