.three_image_section {
	@include paddingTopBottom;
	background-image: $lightBlueGrad;

	.img-crop {
		overflow: hidden;

		p {
			position: absolute;
			top: 0;
			right: 0;
			border-bottom: 1px solid color(white);
			color: color(white);
			text-transform: uppercase;
			padding: 10px 0 5px;
			z-index: 99;
			width: 55%;
		}
	}

	.flex-center {
		.col {
			&:nth-of-type(1) {
				padding-right: $gap;

				.img-crop {
					border-radius: $borderRadius 0 0 $borderRadius;

					@media (max-width: bp(nav)) {
						border-radius: $borderRadius;
					}
				}

				h2 {
					padding: 0 $paddingMain;
				}

				.desc {
					padding: $paddingMain;

					@media (max-width: bp(xl)) {
						padding: #{$paddingMain/2};
					}
				}
			}

			&:nth-of-type(2) {
				.img-crop {
					border-radius: $borderRadius $borderRadius 0 0;

					&:nth-of-type(2) {
						margin-top: $gap;
						border-radius: 0 0 $borderRadius $borderRadius;
					}
				}
			}
		}
	}
}
