.image_grid_section {
	@include paddingTopBottom();

	.flex-center {
		.col {
			@media (max-width: bp(sm)) {
				&:not(:last-of-type) {
					margin-bottom: 20px;
				}
			}

			img {
				height: auto;
				width: 100%;
			}
		}
	}
}
