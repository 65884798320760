/****************************************

accordion SECTION

include js: 
acccordianSection()

optional setting:
data-accordion="solo" : show one item only

basic structure: 
<div class="accordion-section" data-accordion="solo">
    <div class="accordion-item" data-accordion-item>
        <div class="accordion-header" data-accordion-header>
            <div class="accordion-icon">
                <span data-accordion-open>+</span>
                <span data-accordion-close>-</span>
            </div>
            accordion A
        </div>
        <div class="accordion-details">
            <p>accordion A - Content</p>
        </div>
    </div>
  </div>
</div>

****************************************/

.accordion-section {
	.accordion-item {
		.accordion-header {
			display: flex;
			align-items: center;
			min-height: 30px;
			padding: 15px 0;
			cursor: pointer;
			border-bottom: 1px solid color(accent);

			img {
				width: 40px;
				height: auto;
				flex: none;
				margin-right: 10px;
			}

			p {
				margin-bottom: 0;
				width: 100%;
			}

			.accordion-icon.plus {
				min-width: 15px;
				margin-right: 5px;
				display: flex;
				font-size: 48px;
				color: color(accent);

				span[data-accordion-open] {
					@include showLayer;

					line-height: 1;
					text-align: center;
				}

				span[data-accordion-close] {
					@include hideLayer;

					line-height: 1;
					text-align: center;
				}
			}

			.accordion-icon.triangle {
				width: 0;
				height: 0;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-bottom: 10px solid color(accent);
				margin-left: 10px;
				transform: rotate(180deg);
				transition: transform 0.5s;
			}
		}

		.accordion-details {
			width: 100%;
			max-height: 0px;
			overflow: hidden;
			padding: 0 10px;

			transition: all 0.5s;
		}

		&.open {
			.accordion-header .accordion-icon.plus {
				span[data-accordion-open] {
					@include hideLayer;
				}

				span[data-accordion-close] {
					@include showLayer;
				}
			}

			.accordion-header .accordion-icon.triangle {
				transform: rotate(0deg);
			}

			.accordion-details {
				max-height: 1000px;
				padding: 20px 10px;
			}
		}
	}
}
