.accordion_with_icons {
	.accordion-item {
		.accordion-header {
		}

		.accordion-details {
			@media (min-width: bp(nav)) {
				padding-left: 50px;
			}

			p {
				font-size: 1.6rem;
			}
		}

		&.open {
			.accordion-details {
				padding: 20px 10px 20px 50px;

				@media (max-width: bp(nav)) {
					padding: 20px 10px;
				}
			}
		}
	}

	.btn.center {
		margin: $paddingMain auto;
	}
}

.faq_section {
	.tab-section .tab-nav .tab-link {
		width: auto;
		flex: none;
		padding: 10px 15px;
	}

	.accordion-item {
		.accordion-header {
		}

		.accordion-details {
			display: flex;

			@media (max-width: bp(nav)) {
				flex-direction: column;
			}

			.responsive-video {
				width: 50%;
				margin-left: 20px;
				flex: none;

				@media (max-width: bp(nav)) {
					width: 100%;
					margin-left: 0;
					margin-top: 10px;
				}

				img {
					width: 100%;
					height: auto;
				}
			}

			.inner {
				width: 100%;
			}

			p {
				font-size: 1.6rem;
			}
		}

		&.open {
			.accordion-details {
			}
		}
	}
}
