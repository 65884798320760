/****************************************

TYPOGRPAHY

****************************************/

@mixin h1-font-styles {
	font-family: $headlineFont;
	font-size: 4.2rem;
	font-weight: normal;
	line-height: calc(13 / 13);
	//debug border: 1px solid red !important;

	@include elementSpacing;

	@media (max-width: bp(lg)) {
		//font-size: 7rem;
	}
}

h1,
.title1 {
	@include h1-font-styles();
}

@mixin h2-font-styles {
	font-family: $headlineFont;
	font-size: 4.5rem;
	font-weight: normal;
	line-height: calc(87 / 85);
	//debug border: 1px solid orange !important;

	@include elementSpacing;

	@media (max-width: bp(lg)) {
		font-size: 5rem;
	}
}

h2,
.title2 {
	@include h2-font-styles();
}

@mixin h3-font-styles {
	font-family: $headlineFont;
	font-size: 3.2rem;
	font-weight: normal;
	line-height: calc(86 / 70);
	letter-spacing: 1.5px;
	//debug border: 1px solid yellow !important;

	@include elementSpacing;

	@media (max-width: bp(lg)) {
		font-size: 2.8rem;
	}
}

h3,
.title3 {
	@include h3-font-styles();
}

@mixin h4-font-styles {
	font-family: $headlineFont;
	font-size: 2.4rem;
	font-weight: 500;
	line-height: calc(52 / 32);
	//debug border: 1px solid green !important;

	@include elementSpacing;

	@media (max-width: bp(lg)) {
		font-size: 2.4rem;
	}
}

h4,
.title4 {
	@include h4-font-styles();
}

@mixin h5-font-styles {
	font-family: $headlineFont;
	font-size: fontsize(h5);
	font-weight: 400;
	line-height: calc(45 / 25);
	//debug border: 1px solid magenta !important;

	@include elementSpacing;

	@media (max-width: bp(lg)) {
		font-size: 1.8rem;
	}
}

h5,
.title5 {
	@include h5-font-styles();
}

h6,
.h6,
.title6 {
	font-family: $headlineFont;
}

@mixin p-font-styles {
	font-weight: 400;
	font-size: 1.8rem;
	line-height: 3rem;
	//debug border: 1px solid black !important;

	@media (max-width: bp(lg)) {
		//font-size: 5rem;
	}

	@include elementSpacing;

	li:not(:last-of-type) {
		@include elementSpacing;
	}

	sup {
		vertical-align: baseline;
		position: relative;
		top: -0.4em;
	}

	&.disclaimer {
		font-size: 1.4rem;
		line-height: 1.6rem;
	}

	&.eyebrow {
		font-size: 1.8rem;
		line-height: 2.1rem;
	}

	a {
		color: color(accent);
		text-decoration: none;
	}
}

p,
ul,
ol {
	@include p-font-styles();
}

ol {
	padding-left: 1em;
}

@mixin bullets {
	li {
		position: relative;
		padding: 0 0 0 20px;

		&:before {
			content: "";
			position: absolute;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background-color: color(black);
			top: 0.8em;
			left: 0;
		}
	}
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		margin: 0;
		padding: 0;
	}

	&.bullets {
		position: relative;

		@include bullets;
	}
}

.strong,
strong {
	font-weight: 800;
}

.uppercase {
	text-transform: uppercase;
}

.sans {
	font-family: $bodyFont;
}
