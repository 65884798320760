.page-id-268 footer,
.page-id-268 .footer-form.global {
	display: none;
}

.find_your_home_section {
	height: calc(100vh - 0px);

	@media (max-width: bp(nav)) {
		height: auto;
	}

	.debug-title {
		display: none;
	}

	.title1 {
		font-size: 6rem;

		@media (max-width: bp(xxl)) {
			font-size: 5rem;
		}

		@media (max-width: bp(xl)) {
			font-size: 4rem;
		}
	}

	.flex-center {
		height: 100%;
		align-items: stretch;

		@media (max-width: bp(nav)) {
			align-items: flex-start;
		}

		.col {
			&.filter-col {
				width: 50%;
				padding-top: 100px;

				@media (max-width: bp(xxl)) {
					width: 50%;
				}

				@media (max-width: bp(tablet)) {
					width: 100%;
				}
			}

			&.map-col {
				width: 50%;

				@media (max-width: bp(xxl)) {
					width: 50%;
				}

				@media (max-width: bp(tablet)) {
					display: none;
				}
			}
		}
	}

	.the-map {
		width: 100%;
		height: 100%;
	}

	.filters {
		@include p-font-styles();
		margin-bottom: 0;
		display: flex;
		flex-direction: column;
		position: relative;
		padding-right: $paddingMain;
		padding-top: #{$paddingMain/2};
		padding-left: $largeSidebarWidth;

		@media (max-width: bp(xxl)) {
			padding-left: #{$paddingMain/2};
		}

		@media (max-width: bp(nav)) {
			padding: #{$paddingMain/2};
		}

		h1,
		p {
			&[data-type] {
				&.hidden {
					display: none;
				}
			}
		}

		.title1 {
			margin-bottom: 2.5rem;
		}

		.btm-filters {
			align-items: center;
			flex-direction: row;

			.col {
				display: flex;
				margin-right: 15px;

				h4 {
					margin-bottom: 0;
				}

				&:nth-of-type(2) {
					justify-content: flex-end;
					align-items: flex-end;
					margin-right: 0;
				}
			}

			.more-filters {
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;

				img {
					margin-left: 10px;
				}
			}
		}
	}

	.list {
		height: calc(100% - 253px);
		width: calc(100% + #{$paddingMain * 2});
		margin-left: #{-$paddingMain * 2};
		overflow-y: scroll;
		scroll-behavior: smooth;
		padding: $paddingMain 0 $paddingMain #{$paddingMain * 2};

		@media (max-width: bp(lg)) {
			width: calc(100% + #{$paddingMain});
			margin-right: 0;
			padding: $paddingMain 0 $paddingMain $paddingMain;
			margin-left: #{-$paddingMain * 1};
		}

		@media (max-width: bp(nav)) {
			height: auto;
			overflow-y: auto;
			padding: #{$paddingMain/2} 0;
			margin-left: 0;
			width: 100%;
		}
	}

	.count {
		display: none;

		&.active {
			display: block;
		}
	}

	.item {
		width: calc(100% - 10px);
		display: flex;
		flex-direction: column;
		padding: 45px 20px 45px 0;
		margin-right: 10px;
		position: relative;
		background: linear-gradient(0deg, #fff 0%, #fff 100%);
		transition: background $animationDuration;
		padding-left: $largeSidebarWidth;

		@media (max-width: bp(xxl)) {
			padding-left: #{$paddingMain/2};
		}

		@media (max-width: bp(nav)) {
			margin-right: 0;
			width: 100%;
			padding: 45px 20px;
		}

		&.hide {
			display: none;
		}

		&.select {
			background: $lightBlueGrad;
		}

		.flex-center {
			.col {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				&:nth-of-type(1) {
					align-items: stretch;

					a {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
					}

					.img-crop {
						border-radius: $borderRadius 0 0 $borderRadius;
						overflow: hidden;

						@media (max-width: bp(nav)) {
							border-radius: $borderRadius;
							margin-bottom: 15px;
						}
					}
				}

				&:nth-of-type(2) {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding-left: #{$gap * 2};

					p.title4 {
						font-weight: 500;
					}

					p {
						margin: 0;
						font-weight: 500;

						&:nth-of-type(2) {
							font-weight: 400;
						}

						&:nth-of-type(3) {
							margin: 20px 0 0 0;
						}

						&:nth-of-type(4) {
							margin: 0 0 10px 0;
						}
					}

					ul {
						display: flex;
						flex-wrap: wrap;

						li {
						}
					}
				}
			}
		}

		.arrow-link {
			width: 35px;
			height: 35px;
			transform: rotate(180deg);
			position: absolute;
			bottom: 10px;
			right: 10px;
		}
	}
}

.filter {
	@include p-font-styles();
	margin-bottom: 0;
	display: flex;
	flex-direction: column;
	position: relative;
	border: none;
	margin: 0 12px 0 0;
	padding: 0;

	@media (max-width: bp(nav)) {
		margin: 0 0 5px 0;
	}

	p {
		margin: 0;
	}

	&.horizontal {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		@media (max-width: bp(tablet)) {
			flex-wrap: wrap;

			> * {
				width: 30%;
			}
		}
	}

	p.dropdown {
		display: flex;
		width: 100% !important;
		justify-content: space-between;
		align-items: center;
		padding: 2px 10px;
		font-size: 1.6rem;

		cursor: pointer;
		border: 1px solid color(grey, outlines);

		&:after {
			content: "";
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			border-top: 6px solid color(accent);
			transform: translate(0, 0);
			display: block;
			transition: transform $animationDuration;
		}
	}

	&.active p.dropdown:after {
		transform: translate(0, 0) rotate(180deg);
	}

	fieldset {
		border: none;
		padding: 0;
		position: relative;
	}

	select {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
	}

	&.overlay-filter {
		position: relative;
		z-index: 99;

		.overlay {
			position: absolute;
			top: 40px;
			left: 0;
			min-width: 225px;
			padding: 10px;
			border: 1px solid color(grey, outlines);
			background: color(white);
			z-index: 999;
			display: fle;
			justify-content: center;
			opacity: 0;
			pointer-events: none;
			transition: opacity $animationDuration;

			@media (max-width: bp(nav)) {
				position: relative;
				max-height: 0;
				padding: 0px 10px;
				top: auto;
				left: auto;
				overflow: hidden;
				transition: max-width $animationDuration, opacity $animationDuration;
			}

			.filter {
				margin: 0;
				width: 100%;
				padding: 0 10px;
			}

			.filter:nth-of-type(2) {
				border-left: 1px solid color(grey, outlines);
			}
		}

		&.price {
			z-index: 9;

			.overlay {
				min-width: 300px;
			}

			.filter {
				width: 50%;
				padding: 0 10px;
			}
		}

		&.active .overlay {
			opacity: 1;
			pointer-events: auto;

			@media (max-width: bp(nav)) {
				max-height: 900px;
				padding: 10px;
			}
		}
	}

	.cb {
		display: flex;
		align-items: center;
		position: relative;
		cursor: pointer;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;

			&:checked {
				~ .checkmark {
					background-color: color(black);
				}

				~ .checkmark:after {
					display: block;
				}
			}
		}

		.checkmark {
			position: relative;
			height: 20px;
			width: 20px;
			margin-right: 10px;
			background-color: color(white);
			border: 1px solid color(grey, underlines);
			transition: background-color $animationDuration;

			&:after {
				//content: "";
				position: absolute;
				display: none;
				left: 9px;
				top: 5px;
				width: 5px;
				height: 10px;
				border: solid white;
				border-width: 0 3px 3px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}

	&.sqft {
		width: 100%;

		@media (max-width: bp(nav)) {
			padding-top: 20px;
		}

		.inner {
			align-items: center;
			justify-content: space-between;
			width: 450px;
			display: flex;

			@media (max-width: bp(nav)) {
				width: 100%;
				flex-direction: column;
			}
		}

		.label {
			min-width: 105px;

			@media (max-width: bp(nav)) {
				width: 100%;
			}
		}

		fieldset {
			min-width: 130px;

			@media (max-width: bp(nav)) {
				width: 100%;
			}

			p {
				width: 100% !important;
			}
		}
	}

	&.price {
		.flex {
			display: flex;
			width: 400px;
			margin: 0 auto;

			@media (max-width: bp(nav)) {
				width: 100%;
			}

			label {
				font-size: 1.4rem;
				margin-top: -8px;
				margin-right: 15px;
			}

			.sliders_control {
				width: 100%;
			}
		}
	}
}

.gm-style-iw-d a {
	color: color(black);
	text-decoration: none;
}

.gm-style-iw-c {
	padding: 0 !important;
	border-radius: $borderRadius !important;
}

.gm-style-iw-d {
	overflow: hidden !important;
	padding: 0 !important;
}

.gm-style-iw {
	width: 350px !important;
}

.gm-style-iw-tc {
	display: none !important;
}

.gm-ui-hover-effect {
	display: none !important;
}

.info-window {
	width: 350px;
	height: 175px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: stretch;
	font-size: 12px;

	.img-crop {
		padding: 0;
		height: 100%;
	}

	img {
		width: 100%;
	}

	.col {
		width: 50%;

		&.content {
			background-image: $peachGrad;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			padding: 10px;

			.arrow-link {
				position: absolute;
				bottom: 10px;
				right: 10px;
				width: 25px;
				height: 25px;
				transform: rotate(180deg);
			}
		}
	}

	p {
		margin: 0 0 5px 0;
		line-height: 1;
		width: 100%;
		font-size: 14px;

		&:nth-of-type(2) {
			margin-top: 5px;
		}

		&:nth-of-type(4),
		&:nth-of-type(5) {
			font-weight: 500;
		}

		&:nth-of-type(4),
		&:nth-of-type(6) {
			margin-top: 5px;
		}
	}

	.btn {
		width: 100%;
		text-align: right;
	}
}

.range_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: #{$paddingMain} 0 #{$paddingMain/2};

	@media (max-width: bp(nav)) {
		margin: #{$paddingMain/2} 0 0;
	}

	.sliders_control {
		position: relative;
	}

	input[type="range"]::-webkit-slider-thumb {
		-webkit-appearance: none;
		pointer-events: all;
		width: 15px;
		height: 15px;
		background-color: color(accent);
		border-radius: 50%;
		border: none;
		cursor: pointer;

		&:hover {
		}
	}

	input[type="range"]::-moz-range-thumb {
		-webkit-appearance: none;
		pointer-events: all;
		width: 24px;
		height: 24px;
		background-color: #fff;
		border-radius: 50%;
		cursor: pointer;
	}

	input[type="range"]::-webkit-slider-thumb:active {
		box-shadow: inset 0 0 3px color(accent), 0 0 9px color(accent);
	}

	input[type="range"] {
		-webkit-appearance: none;
		appearance: none;
		height: 2px;
		width: 100%;
		position: absolute;
		background-color: #c6c6c6;
		pointer-events: none;
	}

	#fromSlider {
		height: 0;
		z-index: 1;
	}

	.min,
	.max {
		position: absolute;
		top: 10px;
		font-size: 12px;
		width: 50px;
		transform: translate((7.5px - 25px), 0);
		text-align: center;
	}

	.max {
		transform: translate((-7.5px - 25px), 0);
	}
}
