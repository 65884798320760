.general_section {
	@include paddingTopBottom();

	img {
		max-width: 100%;
		width: 100%;
		height: auto;
	}

	/* Enable WYSIWYG Alignment */
	p img {
		float: left;
		margin: 0 20px 5px 0;

		&.alignright {
			float: right;
			margin: 0 0 5px 20px;
		}

		&.aligncenter {
			float: none;
			margin: 5px auto;
			clear: both;
			display: block;
		}
	}
}
