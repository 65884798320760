/****************************************

BUTTONS

****************************************/

@mixin btnStyle {
	padding: 15px 25px;
	font-size: 1.8rem;
	line-height: 1;
	font-weight: 300;
	color: color(white);
	background-color: color(black);
	text-decoration: none;
	text-align: center;
	display: table;
	transition: background-color $animationDuration, color $animationDuration;

	&:hover {
		background-color: color(accent);
		color: color(white);
	}
}

.btn {
	@include btnStyle;

	&.center {
		margin: 0 auto;
		display: table;
		width: auto;
	}
}
