.hero_slider {
	h1,
	h2,
	h3,
	h4,
	h5,
	p,
	ul li {
		text-align: center;
		color: color(white);
	}

	.swiper-slide {
		@include paddingTopBottom();
	}
}
