.table_section {
	@include paddingTopBottom();

	table {
		@include p-font-styles();

		tr {
			display: flex;
			width: 100%;

			td,
			th {
				width: 100%;
				text-align: left;
			}
		}
	}
}
