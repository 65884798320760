.hero_section {
	.full {
		background-size: cover;
		background-position: center center;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		@include paddingTopBottom();

		h1,
		h2,
		h3,
		h4,
		h5,
		p,
		ul li {
			text-align: center;
			//color: color(white);
		}
	}

	.top-section {
		height: 100vh;
		max-height: 1080px;
	}

	.filters {
		max-width: 625px;
		border-radius: $borderRadius;
		padding: #{$paddingMain/2};
		background: rgba(255, 255, 255, 0.75);
		margin: 0 auto;
		position: relative;

		.range_container {
			margin-top: 40px;
		}

		.inner {
		}
	}

	.btm {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: bp(nav)) {
			flex-direction: column-reverse;
			padding-top: 15px;
		}

		a {
			text-decoration: underline;
			color: color(accent);
			font-size: 1.8rem;

			@media (max-width: bp(nav)) {
				align-self: flex-start;
			}
		}

		.run-search {
			width: 30px;
			height: auto;
			cursor: pointer;
			transition: transform 0.5s;

			@media (max-width: bp(nav)) {
				align-self: flex-end;
			}

			&:hover {
				transform: scale(1.05);
			}

			img {
				width: 100%;
				height: auto;
				transform: rotate(180deg);
			}
		}
	}
}
