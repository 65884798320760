/****************************************

FIXED HEADER

****************************************/
#wpadminbar {
	@media (max-width: bp(sm)) {
		display: none !important;
	}
}

html {
	@media (max-width: bp(sm)) {
		margin-top: 0 !important;
	}
}

body.freeze {
	height: 100%;
	overflow: hidden;
}

/****************************************

FIXED HEADER

****************************************/
#wpadminbar {
	@media (max-width: bp(sm)) {
		display: none !important;
	}
}

html {
	@media (max-width: bp(sm)) {
		margin-top: 0 !important;
	}
}

$mobileNav: 1085px;

header {
	width: 100vw;
	z-index: 999;
	position: fixed;
	padding: 0;
	top: 0;
	left: 0;
	background: none;
	pointer-events: none;
	transition: all 0.5s;

	@media (max-width: bp(nav)) {
		background: rgba(255, 255, 255, 1);
	}

	&.scroll,
	&.drop-in {
		@media (max-width: bp(nav)) {
			position: fixed;
			top: 0;
			animation-name: down;
			animation-duration: 1.5s;
		}
	}

	@keyframes down {
		from {
			top: -400px;
		}
		to {
			top: 0;
		}
	}

	@at-root .logged-in header {
		top: 32px;

		@media (max-width: bp(sm)) {
			top: 45px;

			&.scroll {
				top: 0;
			}
		}
	}

	.btn-apply {
		position: absolute;
		right: 80px;
		background: color(white);
		border: 1px solid color(accent);
		color: color(accent);
		text-transform: uppercase;
		border-radius: 0 0 10px 10px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
		pointer-events: auto;
		padding: 15px 20px;
		font-size: 16px;
		text-decoration: none;

		@media (max-width: bp(nav)) {
			font-size: 14px;
			padding: 5px 15px;
			right: 70px;
		}
	}

	.logo {
		height: 75px;
		width: auto;
		display: block;
		margin: 0;
		position: relative;
		pointer-events: auto;
		padding: 0 20px;

		&:after {
			//content: "";
			width: 608px;
			height: 500px;
			position: absolute;
			top: -300px;
			left: -200px;
			z-index: 0;
			background-image: radial-gradient(circle, #fff 0, #fff 32%, hsla(0, 0%, 100%, 0) 62%, hsla(0, 0%, 100%, 0) 100%);

			@media (max-width: bp(nav)) {
				display: none;
			}
		}

		@media (max-width: bp(nav)) {
			padding-left: 0;
			height: 45px;
			margin: 10px;
		}

		img {
			height: 100%;
			width: auto;
			z-index: 9;
			position: relative;
		}
	}

	.inner {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		width: 100%;
		z-index: 98;
		position: relative;

		@media (max-width: bp(nav)) {
		}
	}

	.cont {
		@media (max-width: bp(nav)) {
			width: 100%;
			margin: 0;
		}
	}

	.menu-toggle {
		width: 50px;
		height: 50px;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-direction: column;
		z-index: 99999;
		background-color: color(accent);
		cursor: pointer;
		pointer-events: auto;
		transition: all 0.5s;

		.line {
			border-bottom: 2px solid color(white);
			width: 20px;
			height: 1px;
			position: relative;
			display: block;
			transition: all 0.5s;
		}
	}

	&.active {
		.menu-toggle {
			justify-content: center;

			.line {
				&:nth-of-type(3) {
					opacity: 0;
				}

				&:nth-of-type(1) {
					transform: rotate(45deg);
				}

				&:nth-of-type(2) {
					transform: rotate(-45deg) translate(1px, -2px);
				}
			}
		}

		nav {
			width: 250px;
			height: auto;
			pointer-events: auto;

			//@media (max-width: $mobileNav) {
			right: 0;
			//}
		}
	}

	&.scroll nav {
		background: rgba(255, 255, 255, 0.95) !important;
	}

	nav {
		width: 0;
		overflow: hidden;
		position: fixed;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		background: rgba(255, 255, 255, 0.95) !important;
		transition: width $animationDuration, padding $animationDuration, right #{$animationDuration * 1.5};

		//@media (max-width: $mobileNav) {
		width: 250px;
		height: auto;
		right: -250px;
		background: color(white);
		//padding-top: 110px;
		//}

		@media (max-width: bp(nav)) {
			padding-top: 50px;
		}

		.menu {
			padding: 5px 10px;
			display: flex;
			flex-direction: row;

			//@media (max-width: $mobileNav) {
			flex-direction: column;
			padding: 5px 10px;
			max-width: 500px;
			margin: 0 auto;
			//}

			li {
				margin: 0;

				&:not(:last-of-type) {
					//margin-bottom: 10px;
				}

				a {
					margin: 0;
					color: color(black);
					text-decoration: none;
					font-family: $headlineFont;
					padding: 12px 10px;
					min-height: 59px;
					display: flex;
					align-items: center;
					text-align: left;
					justify-content: flex-start;
					width: 100%;
					position: relative;
					font-weight: 100;
					transition: color $animationDuration;

					/*@media (max-width: 1435px) {
						font-size: 14px;
					}

					@media (max-width: 1200px) {
						padding: 12px 5px;
					}

					@media (max-width: bp(nav)) {*/
					min-height: inherit;
					padding: 5px 10px;
					//}

					&:after {
						position: absolute;
						height: 1px;
						bottom: 10px; //18px
						left: 50%;
						width: 0;
						//content: "";
						display: block;
						background: color(black);
						transition: left $animationDuration, width $animationDuration;
					}

					&:hover {
						&:after {
							left: 25%;
							width: calc(50%);
						}
					}

					&.active {
						//color: color(accent);
					}
				}

				&.current_page_item {
					a {
						//color: color(accent);
					}
				}
			}
		}
	}
}

body.scroll {
	//padding-top: 107px;

	@media (max-width: bp(nav)) {
		padding-top: 0;
	}
}

.header-spacing {
	width: 100%;
	height: 107px;
}

.logged-in header.active nav {
	top: 35px;
}
